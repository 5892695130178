<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">应用列表</div>
      <div class="new-header-right">
        <el-button
          class="btn"
          @click="dialogForAddApply = true"
          type="primary"
          plain
          >新建应用</el-button
        >
      </div>
    </el-header>
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      @keyup.enter.native="onSubmit"
    >
      <el-form-item label="应用名称">
        <el-input
          v-model="searchForm.applyName"
          placeholder="应用名称"
          size="small"
          clearable
          @clear="searchForm.applyName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="线路" prop="state">
        <el-select
          v-model="searchForm.lineId"
          clearable
          @clear="searchForm.lineId = null"
          placeholder="线路"
          size="small"
        >
          <el-option
            v-for="item in lineList"
            :key="item.lineId"
            :value="item.lineId"
            :label="item.lineName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-select
          v-model="searchForm.state"
          clearable
          @clear="searchForm.state = null"
          placeholder="状态"
          size="small"
        >
          <el-option label="启用" :value="1"> </el-option>
          <el-option label="停用" :value="0"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" icon="search" size="small"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      style="width: 100%"
      ref="list"
      stripe
      size="small"
    >
      <el-table-column
        prop="applyName"
        label="应用名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="lineName"
        label="线路名称"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="appId"
        label="appId"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="appToken"
        label="appToken"
        align="center"
      ></el-table-column>
      <el-table-column prop="stateName" label="状态" align="center" width="200">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.state"
            active-text="启用"
            inactive-text="停用"
            :active-value="1"
            :inactive-value="0"
            @change="handleChangeApplyState(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="adminName"
        label="创建人"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" content="编辑">
            <el-button
              type="text"
              @click="handleInitModifyApply(scope.row)"
              size="small"
            >
              编辑
            </el-button>
          </el-tooltip>
          <el-tooltip placement="top" content="删除">
            <el-button
              type="text"
              style="color: red"
              @click="handleDeleteApply(scope.row)"
              size="small"
            >
              删除
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageVo.current"
        :page-sizes="[2, 10, 20, 50, 100]"
        :page-size="pageVo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog title="新建应用" :visible.sync="dialogForAddApply">
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="应用名称:" prop="applyName">
              <el-input
                v-model="addForm.applyName"
                placeholder="请输入应用名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="appId:" prop="appId">
              <el-input
                v-model="addForm.appId"
                placeholder="请输入appId"
              ></el-input>
            </el-form-item>
            <el-form-item label="appToken:" prop="appToken">
              <el-input
                v-model="addForm.appToken"
                placeholder="请输入appToken"
              ></el-input>
            </el-form-item>
            <el-form-item label="线路:" prop="lineId">
              <el-select v-model="addForm.lineId" placeholder="线路">
                <el-option
                  v-for="item in lineList"
                  :key="item.lineId"
                  :value="item.lineId"
                  :label="item.lineName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" prop="state">
              <el-select v-model="addForm.state" placeholder="状态">
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input
                v-model="addForm.remark"
                type="textarea"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForAddApply = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleAddApply">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑应用" :visible.sync="dialogForModifyApply">
      <el-form
        ref="modifyForm"
        :model="modifyForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="应用名称:" prop="applyName">
              <el-input
                v-model="modifyForm.applyName"
                placeholder="请输入应用名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="appId:" prop="appId">
              <el-input
                v-model="modifyForm.appId"
                placeholder="请输入appId"
              ></el-input>
            </el-form-item>
            <el-form-item label="appToken:" prop="appToken">
              <el-input
                v-model="modifyForm.appToken"
                placeholder="请输入appToken"
              ></el-input>
            </el-form-item>
            <el-form-item label="线路:" prop="lineId">
              <el-select v-model="modifyForm.lineId" placeholder="线路">
                <el-option
                  v-for="item in lineList"
                  :key="item.lineId"
                  :value="item.lineId"
                  :label="item.lineName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" prop="state">
              <el-select v-model="modifyForm.state" placeholder="状态">
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input
                v-model="modifyForm.remark"
                type="textarea"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifyApply = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleModifyApply">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  createApply,
  createUser,
  deleteApplyState,
  deleteUser,
  editApply,
  editApplyState,
  editUser,
  editUserState,
  getApplyListByPage,
  initApply,
  initSeatAccount,
} from "../../api";

export default {
  name: "",
  data() {
    return {
      dialogForAddApply: false,
      dialogForModifyApply: false,
      searchForm: {
        adminId: this.$store.getters.adminId,
        lineId: null,
        state: null,
        applyName: null,
      },
      addForm: {
        applyName: "",
        appId: "",
        appToken: "",
        lineId: null,
        state: null,
        remark: null,
        adminId: this.$store.getters.adminId,
      },
      modifyForm: {
        applyId: "",
        applyName: "",
        appId: "",
        appToken: "",
        lineId: null,
        state: null,
        remark: null,
        adminId: this.$store.getters.adminId,
      },
      list: [],
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
      lineList: [],
      rules: {
        applyName: [
          { required: true, message: "请输入应用名称", trigger: "blur" },
        ],
        lineId: [{ required: true, message: "请选择线路", trigger: "change" }],
      },
    };
  },
  created() {
    this.loadApplyList();
    this.initSelections();
  },
  methods: {
    async initSelections() {
      let data = await initApply({ adminId: this.searchForm.adminId });
      this.lineList = data.lineList;
      this.userList = data.userList;
      this.merchantList = data.merchantList;
    },
    handleDeleteApply(row) {
      if (row.applyId) {
        this.$confirm(`确定删除应用${row.applyName}吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteApplyState({
            applyId: row.applyId,
            adminId: this.$store.getters.adminId,
          }).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "删除成功",
              });
              this.loadApplyList();
            }
          });
        });
      }
    },
    handleChangeApplyState(row) {
      let param = {
        applyId: row.applyId,
        state: row.state,
        adminId: this.$store.getters.adminId,
      };
      editApplyState(param).then((data) => {
        if (data) {
          let message =
            row.state === 1
              ? `启用应用${row.applyName}成功`
              : `停用应用${row.applyName}成功`;
          this.$notify({
            type: "success",
            title: "成功",
            message: message,
          });
        }
      });
    },
    handleAddApply() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          };
          createApply(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "创建应用成功",
              });
              this.dialogForAddApply = false;
              this.$refs.addForm.resetFields();
              this.loadApplyList();
            }
          });
        }
      });
    },
    handleInitModifyApply(row) {
      Object.assign(this.modifyForm, row);
      this.dialogForModifyApply = true;
    },
    handleModifyApply() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.modifyForm,
          };
          editApply(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "编辑应用成功",
              });
              this.dialogForModifyApply = false;
              this.loadApplyList();
            }
          });
        }
      });
    },
    onSubmit() {
      this.pageVo.current = 1;
      this.loadApplyList();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadApplyList();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadApplyList();
    },
    loadApplyList() {
      let params = {
        ...this.pageVo,
        params: this.searchForm,
      };
      this.loading = true;
      getApplyListByPage(params).then((data) => {
        console.log(data);
        this.list = data.records;
        this.totalCount = data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
